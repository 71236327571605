import { default as adminLUxGEttwhHMeta } from "/Users/jedesh/Work/Repository/console/pages/admin.vue?macro=true";
import { default as dataLHtZoAXuXtMeta } from "/Users/jedesh/Work/Repository/console/pages/data.vue?macro=true";
import { default as faqeI1YmXr4egMeta } from "/Users/jedesh/Work/Repository/console/pages/guides/faq.vue?macro=true";
import { default as indexRDjrGu05fMMeta } from "/Users/jedesh/Work/Repository/console/pages/guides/index.vue?macro=true";
import { default as index1adPaDnuQoMeta } from "/Users/jedesh/Work/Repository/console/pages/index.vue?macro=true";
import { default as maintenanceR3LU99zSB4Meta } from "/Users/jedesh/Work/Repository/console/pages/maintenance.vue?macro=true";
import { default as apr_452023MsxP7UGbXnMeta } from "/Users/jedesh/Work/Repository/console/pages/release-notes/apr-2023.vue?macro=true";
import { default as aug_452023Qx7w08mzZjMeta } from "/Users/jedesh/Work/Repository/console/pages/release-notes/aug-2023.vue?macro=true";
import { default as aug_452024x4mTKOCCgBMeta } from "/Users/jedesh/Work/Repository/console/pages/release-notes/aug-2024.vue?macro=true";
import { default as dec_452023lzFBi2GYQ0Meta } from "/Users/jedesh/Work/Repository/console/pages/release-notes/dec-2023.vue?macro=true";
import { default as feb_452023spwYWrDcs2Meta } from "/Users/jedesh/Work/Repository/console/pages/release-notes/feb-2023.vue?macro=true";
import { default as indexO27RMkc5r2Meta } from "/Users/jedesh/Work/Repository/console/pages/release-notes/index.vue?macro=true";
import { default as jan_452024lKXhwstXUyMeta } from "/Users/jedesh/Work/Repository/console/pages/release-notes/jan-2024.vue?macro=true";
import { default as jul_452023zkOHagwsuGMeta } from "/Users/jedesh/Work/Repository/console/pages/release-notes/jul-2023.vue?macro=true";
import { default as jul_452024Z0C6V6KFfdMeta } from "/Users/jedesh/Work/Repository/console/pages/release-notes/jul-2024.vue?macro=true";
import { default as jun_452023yQxucVRr8UMeta } from "/Users/jedesh/Work/Repository/console/pages/release-notes/jun-2023.vue?macro=true";
import { default as jun_452024eAsmgXpOlTMeta } from "/Users/jedesh/Work/Repository/console/pages/release-notes/jun-2024.vue?macro=true";
import { default as mar_452023rNZPQSttIhMeta } from "/Users/jedesh/Work/Repository/console/pages/release-notes/mar-2023.vue?macro=true";
import { default as mar_452024cu6Ue7YcK7Meta } from "/Users/jedesh/Work/Repository/console/pages/release-notes/mar-2024.vue?macro=true";
import { default as may_452023ENtwA5O0QUMeta } from "/Users/jedesh/Work/Repository/console/pages/release-notes/may-2023.vue?macro=true";
import { default as may_452024p6hVE257c8Meta } from "/Users/jedesh/Work/Repository/console/pages/release-notes/may-2024.vue?macro=true";
import { default as nov_452023062OeYbC18Meta } from "/Users/jedesh/Work/Repository/console/pages/release-notes/nov-2023.vue?macro=true";
import { default as nov_452024790skRZppuMeta } from "/Users/jedesh/Work/Repository/console/pages/release-notes/nov-2024.vue?macro=true";
import { default as oct_452023AZdB8fa6pCMeta } from "/Users/jedesh/Work/Repository/console/pages/release-notes/oct-2023.vue?macro=true";
import { default as oct_452024WTs2r5YbmZMeta } from "/Users/jedesh/Work/Repository/console/pages/release-notes/oct-2024.vue?macro=true";
import { default as sep_452023p7bgaywr1GMeta } from "/Users/jedesh/Work/Repository/console/pages/release-notes/sep-2023.vue?macro=true";
import { default as commercial_45analyticsgwib7qizU1Meta } from "/Users/jedesh/Work/Repository/console/pages/sample-dashboards/commercial-analytics.vue?macro=true";
import { default as indexLEKCKT0S3rMeta } from "/Users/jedesh/Work/Repository/console/pages/sample-dashboards/index.vue?macro=true";
import { default as mdm_45customer_45master64ZThWdfPUMeta } from "/Users/jedesh/Work/Repository/console/pages/sample-dashboards/mdm-customer-master.vue?macro=true";
import { default as sales_45opsqhnH7PpyWeMeta } from "/Users/jedesh/Work/Repository/console/pages/sample-dashboards/sales-ops.vue?macro=true";
import { default as index6o5Kcr2AJqMeta } from "/Users/jedesh/Work/Repository/console/pages/schema/index.vue?macro=true";
import { default as indexwpGdU0P5JnMeta } from "/Users/jedesh/Work/Repository/console/pages/viz/index.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/admin.vue")
  },
  {
    name: "data",
    path: "/data",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/data.vue")
  },
  {
    name: "guides-faq",
    path: "/guides/faq",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/guides/faq.vue")
  },
  {
    name: "guides",
    path: "/guides",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/guides/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceR3LU99zSB4Meta || {},
    component: () => import("/Users/jedesh/Work/Repository/console/pages/maintenance.vue")
  },
  {
    name: "release-notes-apr-2023",
    path: "/release-notes/apr-2023",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/release-notes/apr-2023.vue")
  },
  {
    name: "release-notes-aug-2023",
    path: "/release-notes/aug-2023",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/release-notes/aug-2023.vue")
  },
  {
    name: "release-notes-aug-2024",
    path: "/release-notes/aug-2024",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/release-notes/aug-2024.vue")
  },
  {
    name: "release-notes-dec-2023",
    path: "/release-notes/dec-2023",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/release-notes/dec-2023.vue")
  },
  {
    name: "release-notes-feb-2023",
    path: "/release-notes/feb-2023",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/release-notes/feb-2023.vue")
  },
  {
    name: "release-notes",
    path: "/release-notes",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/release-notes/index.vue")
  },
  {
    name: "release-notes-jan-2024",
    path: "/release-notes/jan-2024",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/release-notes/jan-2024.vue")
  },
  {
    name: "release-notes-jul-2023",
    path: "/release-notes/jul-2023",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/release-notes/jul-2023.vue")
  },
  {
    name: "release-notes-jul-2024",
    path: "/release-notes/jul-2024",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/release-notes/jul-2024.vue")
  },
  {
    name: "release-notes-jun-2023",
    path: "/release-notes/jun-2023",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/release-notes/jun-2023.vue")
  },
  {
    name: "release-notes-jun-2024",
    path: "/release-notes/jun-2024",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/release-notes/jun-2024.vue")
  },
  {
    name: "release-notes-mar-2023",
    path: "/release-notes/mar-2023",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/release-notes/mar-2023.vue")
  },
  {
    name: "release-notes-mar-2024",
    path: "/release-notes/mar-2024",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/release-notes/mar-2024.vue")
  },
  {
    name: "release-notes-may-2023",
    path: "/release-notes/may-2023",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/release-notes/may-2023.vue")
  },
  {
    name: "release-notes-may-2024",
    path: "/release-notes/may-2024",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/release-notes/may-2024.vue")
  },
  {
    name: "release-notes-nov-2023",
    path: "/release-notes/nov-2023",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/release-notes/nov-2023.vue")
  },
  {
    name: "release-notes-nov-2024",
    path: "/release-notes/nov-2024",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/release-notes/nov-2024.vue")
  },
  {
    name: "release-notes-oct-2023",
    path: "/release-notes/oct-2023",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/release-notes/oct-2023.vue")
  },
  {
    name: "release-notes-oct-2024",
    path: "/release-notes/oct-2024",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/release-notes/oct-2024.vue")
  },
  {
    name: "release-notes-sep-2023",
    path: "/release-notes/sep-2023",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/release-notes/sep-2023.vue")
  },
  {
    name: "sample-dashboards-commercial-analytics",
    path: "/sample-dashboards/commercial-analytics",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/sample-dashboards/commercial-analytics.vue")
  },
  {
    name: "sample-dashboards",
    path: "/sample-dashboards",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/sample-dashboards/index.vue")
  },
  {
    name: "sample-dashboards-mdm-customer-master",
    path: "/sample-dashboards/mdm-customer-master",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/sample-dashboards/mdm-customer-master.vue")
  },
  {
    name: "sample-dashboards-sales-ops",
    path: "/sample-dashboards/sales-ops",
    component: () => import("/Users/jedesh/Work/Repository/console/pages/sample-dashboards/sales-ops.vue")
  },
  {
    name: "schema",
    path: "/schema",
    meta: index6o5Kcr2AJqMeta || {},
    component: () => import("/Users/jedesh/Work/Repository/console/pages/schema/index.vue")
  },
  {
    name: "viz",
    path: "/viz",
    meta: indexwpGdU0P5JnMeta || {},
    component: () => import("/Users/jedesh/Work/Repository/console/pages/viz/index.vue")
  }
]