import revive_payload_client_4sVQNw7RlN from "/Users/jedesh/Work/Repository/console/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/jedesh/Work/Repository/console/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/jedesh/Work/Repository/console/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/jedesh/Work/Repository/console/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/Users/jedesh/Work/Repository/console/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/jedesh/Work/Repository/console/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_2LpVRIemQY from "/Users/jedesh/Work/Repository/console/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/jedesh/Work/Repository/console/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/Users/jedesh/Work/Repository/console/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/jedesh/Work/Repository/console/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/jedesh/Work/Repository/console/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/Users/jedesh/Work/Repository/console/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import primevue_plugin_egKpok8Auk from "/Users/jedesh/Work/Repository/console/.nuxt/primevue-plugin.mjs";
import plugin_client_XxbS7tGyhp from "/Users/jedesh/Work/Repository/console/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import error_handler_kEP5FliEXj from "/Users/jedesh/Work/Repository/console/plugins/error-handler.ts";
import sentry_3AyO8nEfhE from "/Users/jedesh/Work/Repository/console/plugins/sentry.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  view_transitions_client_2LpVRIemQY,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  primevue_plugin_egKpok8Auk,
  plugin_client_XxbS7tGyhp,
  error_handler_kEP5FliEXj,
  sentry_3AyO8nEfhE
]